<template>
  <van-popup v-model="visible" position="bottom" :close-on-click-overlay="false">
    <!-- <div class="popup-header">
      <div class="title">实名认证</div>
      <div class="icon">
        <van-icon name="close" @click="hide" size="24px" />
      </div>
    </div> -->
    <div class="field-imitate">
      <div class="field-label">
        上传身份证<span class="link" @click="showIdUploadExample">(查看示例)</span>：
      </div>
      <div class="field-content">
        <ul>
          <li>
            <div>
              <van-uploader
                :max-count="1"
                :after-read="uploadBackSide"
                v-model="identityVerify.obverse"
                @delete="onFrontImgDel"
              />
            </div>
            <div class="title">人像面</div>
          </li>
          <li>
            <div>
              <van-uploader
                :max-count="1"
                :after-read="uploadReverseSide"
                v-model="identityVerify.reverse"
                @delete="onBackImgDel"
              />
            </div>
            <div class="title">国徽面</div>
          </li>
          <li>
            <div>
              <van-uploader
                @delete="onPersonImgDel"
                :max-count="1"
                :after-read="uploadPersonImg"
                v-model="identityVerify.person"
              />
            </div>
            <div class="title">正面免冠照</div>
          </li>
        </ul>
      </div>
    </div>
    <div class="btn-wrapper">
      <van-button type="danger" class="place-btn" @click="submit">提交申请</van-button>
    </div>
  </van-popup>
</template>

<script>
import { tibetUpload, tibetOrder } from "@/api/api";
import Compressor from "compressorjs";

const imgCompressor = function (file, maxWidth = 1000, maxHeight = 1000, quality = 0.8) {
  return new Promise((resolve, reject) => {
    new Compressor(file, {
      quality,
      maxWidth,
      maxHeight,
      success: (result) => {
        // console.log(result);
        resolve(result);
      },
      error(err) {
        reject(err.message);
      },
    });
  });
};

export default {
  name: "XZCardRealName",
  data() {
    return {
      visible: false,
      orderNo: "",
      productId: "",
      identityVerify: {
        obverse: [],
        reverse: [],
        person: [],
      },
      idCardImages: {
        idCardFront: false,
        idCardBack: false,
        idCardHand: false,
      },
      loading: '',
    };
  },
  components: {},
  methods: {
    submit() {
      const data = {
        orderNo: this.orderNo,
        productId: this.productId,
      };
      this.loading = this.$toast.loading({
        forbidClick: true,
        loadingType: "spinner",
        duration: 0,
      });
      if (!this.idCardImages.idCardFront) {
        this.$toast("请上传身份证人像面照片");
        return;
      }
      if (!this.idCardImages.idCardBack) {
        this.$toast("请上传身份证国徽面照片");
        return;
      }
      if (!this.idCardImages.idCardHand) {
        this.$toast("请上传正面免冠照");
        return;
      }
      tibetOrder(data).then((res) => {
        console.log("🚀 ~ file: XZCardRealName.vue:83 ~ tibetOrder ~ res:", res);
        if (res.result) {
          this.$router.push(`/result/${this.orderNo}`);
        } else {
          this.$toast(res.message);
        }
      }).finally(() => {
        this.loading.clear();
      })
    },
    show(orderNo, productId) {
      this.orderNo = orderNo;
      this.productId = productId;
      this.visible = true;
    },
    hide() {
      this.visible = false;
    },
    showIdUploadExample() {
      this.$emit("showIdUploadExample");
    },

    // 上传身份证
    async handleUploadIdCord(file, type) {
      file.status = "uploading";
      file.message = "上传中";
      const formData = new FormData();
      const result = await imgCompressor(file.file);
      console.log(result);
      formData.append("file", result, result.name);
      let res;
      const params = { uploadPhotoType: type, orderNo: this.orderNo };
      res = await tibetUpload(formData, params);

      if (res) {
        if (res.code === 200) {
          file.status = "done";
          return Promise.resolve(res);
        }
        file.status = "failed";
        file.message = "识别失败";
        return Promise.reject();
      }
      file.status = "failed";
      file.message = "上传失败";
      return Promise.reject();
    },
    // 上传身份证正面
    uploadBackSide(file) {
      this.handleUploadIdCord(file, 1).then((res) => {
        console.log(res);
        if (res.result) {
          this.idCardImages.idCardFront = true;
        }
      });
    },
    // 上传身份证背面
    uploadReverseSide(file) {
      this.handleUploadIdCord(file, 2).then((res) => {
        console.log(res);
        if (res.result) {
          this.idCardImages.idCardBack = true;
        }
      });
    },
    // 上传半身像
    uploadPersonImg(file) {
      this.handleUploadIdCord(file, 3).then((res) => {
        console.log(res);
        if (res.result) {
          this.idCardImages.idCardHand = true;
        }
      });
    },
    onFrontImgDel(file) {
      console.log(file);
      this.idCardImages.idCardFront = false;
    },
    onBackImgDel(file) {
      console.log(file);
      this.idCardImages.idCardBack = false;
    },
    onPersonImgDel(file) {
      console.log(file);
      this.idCardImages.idCardHand = false;
    },
  },
  created() {},
};
</script>

<style lang="less" scoped>
.link {
  color: #3d94f6;
  font-weight: normal;
}
.popup-header {
  position: relative;
  border-bottom: 2px solid #eee;
  .title {
    width: 100%;
    box-sizing: border-box;
    text-align: center;
    font-weight: bold;
    font-size: 18px;
    padding: 16px;
    line-height: 1;
  }
  .icon {
    position: absolute;
    right: 16px;
    top: 0;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.field-imitate {
  padding: 0 16px;
  .field-label {
    padding: 10px 0;
    font-size: 15px;
    font-weight: bold;
    color: #333;
    text-align: left;
  }
  .field-content {
    display: flex;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(235, 237, 240, 0.6);
    ul {
      display: flex;
      li {
        .title {
          color: #86909c;
          font-size: 14px;
          line-height: 1;
        }
      }
    }
  }
}
.btn-wrapper {
  display: flex;
  padding: 20px 10% 20px 10%;
  background-color: #fff;
  .place-btn {
    flex: 1;
    border-radius: 23px;
    background-color: #ec4e2a;
    font-size: 17px;
    font-weight: bold;
    border: none;
  }
}
</style>
