import { render, staticRenderFns } from "./IdCardUploadPopupExample.vue?vue&type=template&id=6154e3dd&scoped=true&"
import script from "./IdCardUploadPopupExample.vue?vue&type=script&lang=js&"
export * from "./IdCardUploadPopupExample.vue?vue&type=script&lang=js&"
import style0 from "./IdCardUploadPopupExample.vue?vue&type=style&index=0&id=6154e3dd&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6154e3dd",
  null
  
)

export default component.exports