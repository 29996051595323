<template>
  <!-- 上传身份证 -->
  <van-popup v-model="visible" position="bottom">
    <div class="popup-header">
      <div class="title">查看示例</div>
      <div class="icon">
        <van-icon name="close" @click="hide" size="24px" />
      </div>
    </div>
    <!-- 
    <div class="real-name-container">
      <div class="real-name-header">号卡实名认证流程</div>
      <div class="real-name-content">
        <div class="real-name-step-title">
          <span class="color-1">01.</span>
          <span>上传身份证照片</span>
        </div>
        <div class="real-name-step-content">
          <div class="step-direction-box">
            <div class="step-point">
              <div class="step-point-inner"></div>
            </div>
            <div class="step-line"></div>
          </div>
          <div class="step-content">
            <div class="step-text-content">
              <div class="step-text-title color-1">上传身份证正面照</div>
              <div class="step-text-tips">
                <span>请拍摄身份证正面原件，</span>
                <span class="color-2">尽量确保单一背景、身份证四边留空、减少反光遮挡。</span>
              </div>
            </div>
            <div class="step-img-content"></div>
          </div>
        </div>
        <div class="real-name-step-content">
          <div class="step-direction-box">
            <div class="step-point">
              <div class="step-point-inner"></div>
            </div>
          </div>
          <div class="step-content">
            <div class="step-text-content">
              <div class="step-text-title color-1">上传身份证反面照</div>
              <div class="step-text-tips">
                <span>请拍摄身份证反面原件，</span>
                <span class="color-2">尽量确保单一背景、身份证四边留空、减少反光遮挡。</span>
              </div>
            </div>
            <div class="step-img-content"></div>
          </div>
        </div>
      </div>
      <div class="real-name-content">
        <div class="real-name-step-title">
          <span class="color-1">02.</span>
          <span>上传正面免冠人像</span>
        </div>
        <div class="real-name-step-content">
          <div class="step-direction-box">
            <div class="step-point">
              <div class="step-point-inner"></div>
            </div>
          </div>
          <div class="step-content">
            <div class="step-text-content">
              <div class="step-text-title color-1">上传人像照</div>
              <div class="step-text-tips">
                <span>请拍摄本人清晰正面免冠人像进行人脸认证；</span>
                <span class="color-2">请在光线较好环境下拍摄，并露出人像五官，面部无遮挡。</span>
              </div>
            </div>
            <div class="step-img-content"></div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="popup-container">
      <!-- <div class="popup-header">
        <div class="title">查看示例</div>
        <div class="icon">
          <van-icon name="close" @click="hide" size="24px" />
        </div>
      </div>
      <div class="popup-wrapper">
        <div class="tips"></div>
        <ul>
          <li v-if="types && Object.keys(types).includes('1')">
            <div class="upload-example">
              <img src="@/assets/id-card-front.png" />
            </div>
            <div class="item-title">正面照</div>
          </li>
          <li v-if="types && Object.keys(types).includes('1')">
            <div class="upload-example">
              <img src="@/assets/id-card-back.png" />
            </div>
            <div class="item-title">背面照</div>
          </li>
          <li v-if="types && Object.keys(types).includes('1')">
            <div class="upload-example">
              <img src="@/assets/id-card-person.png" />
            </div>
            <div class="item-title">半身照</div>
          </li>
        </ul>
      </div> -->
    </div>
  </van-popup>
</template>

<script>
export default {
  name: "IdCardUploadPopup",
  props: {
    types: Object,
  },
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    show() {
      this.visible = true;
    },
    hide() {
      this.visible = false;
    },
  },
};
</script>

<style lang="less" scoped>
.popup-header {
  position: relative;
  border-bottom: 2px solid #eee;
  .title {
    width: 100%;
    box-sizing: border-box;
    text-align: center;
    font-weight: bold;
    font-size: 18px;
    padding: 16px;
    line-height: 1;
  }
  .icon {
    position: absolute;
    right: 16px;
    top: 0;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.real-name-container {
  background-color: #f7f7f7;
  padding: 0 12px;
  .real-name-header {
    font-size: 26px;
    font-weight: bold;
    text-align: left;
  }
  .real-name-content {
    background-color: #fff;
    border-radius: 16px;
    padding: 20px 0;
    margin-bottom: 20px;
    .real-name-step-title {
      text-align: left;
      padding: 0 10px 16px;
      font-weight: bold;
      font-size: 22px;
      display: flex;
      align-items: center;
      span:nth-child(1) {
        margin-right: 10px;
        font-size: 24px;
      }
    }
    .real-name-step-content {
      display: flex;
      .step-direction-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 10px;
        .step-point {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background-color: #fd753b;
          display: flex;
          justify-content: center;
          align-items: center;
          .step-point-inner {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: #fff;
          }
        }
        .step-line {
          flex-grow: 1;
          width: 0;
          height: 10px;
          border: 1px #fd753b dashed;
          margin: 10px;
        }
      }
      .step-content {
        display: flex;
        padding-bottom: 50px;
        .step-text-content {
          flex-basis: 0;
          flex-grow: 1;
          padding: 0 10px 0 0;
          font-size: 14px;
          text-align: left;
          line-height: 1;
          .step-text-title {
            font-weight: bold;
            padding: 3px 0 12px;
          }
          .step-text-tips {
            line-height: 1.4;
            span {
            }
          }
        }
        .step-img-content {
          width: 120px;
          height: 86px;
          background-color: purple;
        }
      }
    }
  }
  .color-1 {
    color: #fd753b;
  }
  .color-2 {
    color: #e02a35;
    font-weight: bold;
  }
}
.w100p {
  width: 100%;
}
.margin-bottom-20 {
  margin-bottom: 20px;
}
.popup-container {
  height: calc(100vh - 52px);
  display: flex;
  flex-direction: column;
  background-image: url("~@/assets/real-name-step.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #f7faff;
  .popup-header {
    position: relative;
    border-bottom: 2px solid #eee;
    .title {
      width: 100%;
      box-sizing: border-box;
      text-align: center;
      font-weight: bold;
      font-size: 18px;
      padding: 16px;
      line-height: 1;
    }
    .icon {
      position: absolute;
      right: 16px;
      top: 0;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .popup-wrapper {
    flex-basis: 0;
    flex-grow: 1;
    overflow-y: scroll;
    width: 100vw;
    box-sizing: border-box;
    padding: 16px;
    .tips {
    }
    ul {
      li {
        .upload-example {
          img {
          }
        }
        .item-title {
        }
      }
      li {
        .upload-example {
          img {
          }
        }
        .item-title {
        }
      }
      li {
        .upload-example {
          img {
          }
        }
        .item-title {
        }
      }
    }
    .upload-example {
      width: calc(100vw - 32px);
      height: calc(64vw - 20px);
      padding: 16px;
      box-sizing: border-box;
      border: 2px solid #eee;
      border-radius: 6px;
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
    .item-title {
      padding: 10px 0 20px;
    }
  }
}
</style>
